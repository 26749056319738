const unitPlugin = require("fela-plugin-unit").default;
const namedKeysPlugin = require("fela-plugin-named-keys").default;

const defaultUnit = "px";

const namedKeys = {
  minWidth768: "@media (min-width: 768px)",
  maxWidth767: "@media (max-width: 767px)",
  minWidth480: "@media (min-width: 481px)",
  maxWidth480: "@media (max-width: 480px)",
};

module.exports = {
  plugins: [unitPlugin(defaultUnit), namedKeysPlugin(namedKeys)],
  devMode: process.env.NODE_ENV !== "production",
};
